import './App.css';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import React from 'react';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import PaymentPage from './pages/payment';
import { PaymentErrorElement } from './pages/payment/PaymentErrorElement';
import PaymentConfirmationPage from './pages/payment-confirmation';

const {
  MODE,
  VITE_GA_MEASUREMENT_ID,
  VITE_SENTRY_DSN,
  VITE_TAWK_PROPERTY_ID,
  VITE_TAWK_WIDGET_ID,
} = import.meta.env;

console.log(`Mode: ${MODE}`);

const queryClient = new QueryClient();
const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#ececec',
    },
    primary: {
      main: '#111111',
      light: '#626262',
      contrastText: '#eeeeee',
    },
  },
});

Sentry.init({
  enabled: MODE !== 'development',
  dsn: VITE_SENTRY_DSN,
  environment: MODE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new CaptureConsole({ levels: ['error', 'warn'] }),
  ],
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <PaymentPage />,
    errorElement: <PaymentErrorElement />,
  },
  {
    path: '/confirmation',
    element: <PaymentConfirmationPage />,
    errorElement: <PaymentErrorElement />,
  },
]);

function App() {
  useEffect(() => {
    ReactGA.initialize(VITE_GA_MEASUREMENT_ID);
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <CssBaseline />
            <RouterProvider router={router} />
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
      <TawkMessengerReact
        propertyId={VITE_TAWK_PROPERTY_ID}
        widgetId={VITE_TAWK_WIDGET_ID}
      />
    </div>
  );
}

export default App;
