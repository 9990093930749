import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

import { rulesLink, termsLink } from './constants';

const { VITE_SCHEDULING_URL } = import.meta.env;

export const PaymentConfirmation = () => {
  return (
    <Grid container direction="column" justifyContent="center" spacing={2}>
      <Typography component={Grid} variant="h3">
        Your payment has been received.
      </Typography>

      <Typography component={Grid} variant="subtitle1">
        A confirmation email will be sent with your session details, please
        check your session time carefully.
        <br />
        (If you do not see an email in your inbox, check your junk folder.)
      </Typography>

      <Typography component={Grid}>
        Please be sure to read our{' '}
        <Link rel="noopener" target="_blank" href={rulesLink}>
          Rules
        </Link>{' '}
        and our{' '}
        <Link rel="noopener" target="_blank" href={termsLink}>
          Terms and Conditions.
        </Link>
      </Typography>

      <Typography component={Grid}>
        Thanks for booking with Sound Studios!
      </Typography>

      <Typography component={Grid}>
        <Link href={VITE_SCHEDULING_URL}>Book another session</Link>
      </Typography>
    </Grid>
  );
};

export default PaymentConfirmation;
