import { MutationFunction, useMutation, UseMutationOptions } from 'react-query';

import { baseClient } from './baseClient';

export interface ICreatePaymentVariables {
  idempotencyKey?: string;
  locationId: string;
  orderId: string;
  sourceId: string;
  verificationToken?: string;
}

export interface IPaymentData {
  id: string;
  orderId: string;
  receiptUrl: string;
  status: 'APPROVED' | 'PENDING' | 'COMPLETED' | 'CANCELED' | 'FAILED';
}

export const createPaymentMutation: MutationFunction<
  IPaymentData,
  Omit<ICreatePaymentVariables, 'locationId'>
> = (variables: Omit<ICreatePaymentVariables, 'locationId'>) => {
  return baseClient
    .post('payment', {
      json: {
        ...variables,
        locationId: import.meta.env.VITE_SQUARE_LOCATION_ID,
      },
    })
    .json();
};

export const useCreatePaymentMutation = (
  options?: Omit<
    UseMutationOptions<
      IPaymentData,
      unknown,
      Omit<ICreatePaymentVariables, 'locationId'>,
      unknown
    >,
    'mutationFn'
  >,
) =>
  useMutation<
    IPaymentData,
    unknown,
    Omit<ICreatePaymentVariables, 'locationId'>
  >(createPaymentMutation, options);
