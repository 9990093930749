import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSearchParams } from 'react-router-dom';

import { useCreateOrderMutation } from '../../lib/api/orders';
import { logger } from '../../lib/utils/logger';
import { PaymentErrorElement } from './PaymentErrorElement';
import PaymentForm from './PaymentForm';

function Payment() {
  const [searchParams] = useSearchParams();
  const {
    mutateAsync,
    isLoading,
    data,
    isError: isRequestError,
  } = useCreateOrderMutation();
  const [isLinkError, setIsLinkError] = useState<boolean>(false);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      title: 'Payment Page',
    });
  }, []);

  useEffect(() => {
    const appointmentId = searchParams.get('appointmentId');
    const serviceId = searchParams.get('serviceId');

    if (appointmentId && serviceId) {
      Sentry.setContext('etermin', {
        appointment_id: appointmentId,
        service_id: serviceId,
      });

      mutateAsync({
        appointmentId,
        serviceId,
      });
    } else {
      logger.warn({
        message: 'Invalid link',
      });
      setIsLinkError(true);
    }
  }, [mutateAsync, searchParams]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 480px)': {
          padding: 0,
        },
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {isRequestError && (
        <PaymentErrorElement error="There was an error processing your request." />
      )}

      {isLinkError && <PaymentErrorElement error="Your link is invalid." />}

      {data && <PaymentForm order={data} />}
    </Container>
  );
}

export default Payment;
