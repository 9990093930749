import * as Sentry from '@sentry/react';

interface ILogOptions {
  metadata?: unknown;
  message?: string;
  exception?: unknown;
}

export class Logger {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  private log(
    level: Sentry.SeverityLevel,
    { exception, message }: ILogOptions,
  ) {
    Sentry.withScope((scope) => {
      scope.setLevel(level);

      if (exception) {
        Sentry.captureException(exception);
      } else if (message) {
        Sentry.captureMessage(message);
      }
    });
  }

  public info(options: ILogOptions) {
    this.log('info', options);
  }

  public warn(options: ILogOptions) {
    this.log('warning', options);
  }

  public error(options: ILogOptions) {
    this.log('error', options);
  }
}

export const logger: Logger = new Logger();
