import { Autocomplete, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { memo } from 'react';
import { Controller, useFormState } from 'react-hook-form';

import ControlledPhoneField from '../../../../lib/forms/ControlledPhoneField';
import ControlledTextField from '../../../../lib/forms/ControlledTextField';
import { stateNames } from './constants';
import { getErrorMessage } from './helpers/getErrorMessage';
import { TBuyerSchema } from './schema';
import { TUseBuyerFormReturn } from './useBuyerForm';

export interface IBuyerFormProps {
  control: NonNullable<TUseBuyerFormReturn['control']>;
}

export const BuyerForm = memo<IBuyerFormProps>(({ control }) => {
  const { errors } = useFormState({ control });

  return (
    <Grid container spacing={1} xs={12}>
      <Grid container spacing={2} xs={12}>
        <Grid xs={12} mb={1} mt={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            Contact
          </Typography>
        </Grid>

        <Grid sm={6} xs={12}>
          <ControlledTextField
            control={control}
            name="email"
            TextFieldProps={{
              error: Boolean(getErrorMessage(errors, 'email')),
              helperText: getErrorMessage(errors, 'email'),
              label: 'Email',
              required: true,
              fullWidth: true,
              variant: 'outlined',
            }}
          />
        </Grid>

        <Grid sm={6} xs={12}>
          <ControlledPhoneField
            control={control}
            name="phone"
            PhoneFieldProps={{
              error: Boolean(getErrorMessage(errors, 'phone')),
              defaultCountry: 'US',
              helperText: getErrorMessage(errors, 'phone'),
              forceCallingCode: true,
              preferredCountries: ['US'],
              label: 'Phone',
              required: true,
              fullWidth: true,
              variant: 'outlined',
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} xs={12}>
        <Grid xs={12} mb={1} mt={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            Billing
          </Typography>
        </Grid>

        <Grid sm={6} xs={12}>
          <ControlledTextField
            control={control}
            name="givenName"
            TextFieldProps={{
              error: Boolean(getErrorMessage(errors, 'givenName')),
              helperText: getErrorMessage(errors, 'givenName'),
              label: 'Cardholder First Name',
              required: true,
              fullWidth: true,
              variant: 'outlined',
            }}
          />
        </Grid>

        <Grid sm={6} xs={12}>
          <ControlledTextField
            control={control}
            name="familyName"
            TextFieldProps={{
              error: Boolean(getErrorMessage(errors, 'familyName')),
              helperText: getErrorMessage(errors, 'familyName'),
              label: 'Cardholder Last Name',
              required: true,
              fullWidth: true,
              variant: 'outlined',
            }}
          />
        </Grid>

        <Grid xs={12}>
          <ControlledTextField
            control={control}
            name="addressLineOne"
            TextFieldProps={{
              error: Boolean(getErrorMessage(errors, 'addressLineOne')),
              helperText: getErrorMessage(errors, 'addressLineOne'),
              label: 'Address Line 1',
              required: true,
              fullWidth: true,
              variant: 'outlined',
            }}
          />
        </Grid>

        <Grid xs={12}>
          <ControlledTextField
            control={control}
            name="addressLineTwo"
            TextFieldProps={{
              error: Boolean(getErrorMessage(errors, 'addressLineTwo')),
              helperText: getErrorMessage(errors, 'addressLineTwo'),
              label: 'Address Line 2 (optional)',
              required: false,
              fullWidth: true,
              variant: 'outlined',
            }}
          />
        </Grid>

        <Grid sm={6} xs={12}>
          <ControlledTextField
            control={control}
            name="city"
            TextFieldProps={{
              error: Boolean(getErrorMessage(errors, 'city')),
              helperText: getErrorMessage(errors, 'city'),
              label: 'City',
              required: true,
              fullWidth: true,
              variant: 'outlined',
            }}
          />
        </Grid>

        <Grid sm={6} xs={12}>
          <Controller
            control={control}
            name="state"
            render={({ field: { onChange, ...rest } }) => {
              const changeHandler = (newVal: TBuyerSchema['state'] | null) => {
                onChange(newVal);
              };

              return (
                <Autocomplete
                  {...rest}
                  onChange={(e, value) => changeHandler(value)}
                  options={stateNames}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(getErrorMessage(errors, 'state'))}
                      helperText={getErrorMessage(errors, 'state')}
                      required
                      label="State"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'state', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default BuyerForm;
