import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { Box } from '@mui/system';
import { memo } from 'react';

export interface IPaymentErrorElementProps {
  error?: string;
}

export const PaymentErrorElement = memo<IPaymentErrorElementProps>(
  ({ error }) => (
    <Box>
      <Typography variant="h6">{error ?? 'An error has occurred'}</Typography>

      <Typography variant="body1">
        Please contact support or click&nbsp;
        <Link href={import.meta.env.VITE_SCHEDULING_URL}>here</Link> to return
        to the booking page to book a session.
      </Typography>
    </Box>
  ),
);
