import { matchIsValidTel } from 'mui-tel-input';
import { z } from 'zod';

import { stateNames } from './constants';

const MESSAGES = {
  givenName: {
    required: 'A first name is required.',
  },
  familyName: {
    required: 'A last name is required.',
  },
  email: {
    required: 'An email is required.',
    valid: 'Please provide a valid email.',
  },
  phone: {
    required: 'A phone number is required.',
    valid: 'Please provide a valid phone number.',
  },
  addressLineOne: {
    required: 'An address line is required.',
  },
  city: {
    required: 'A city is required.',
  },
  state: {
    required: 'A state is required.',
  },
};

export const schema = z
  .object({
    givenName: z
      .string({
        required_error: MESSAGES.givenName.required,
      })
      .trim()
      .min(1, { message: MESSAGES.givenName.required })
      .default(''),
    familyName: z
      .string({
        required_error: MESSAGES.familyName.required,
      })
      .trim()
      .min(1, { message: MESSAGES.familyName.required })
      .default(''),
    addressLineOne: z
      .string({
        required_error: MESSAGES.addressLineOne.required,
      })
      .trim()
      .min(1, { message: MESSAGES.addressLineOne.required }),
    addressLineTwo: z.string().optional().nullable(),
    city: z
      .string({
        required_error: MESSAGES.city.required,
      })
      .trim()
      .min(1, { message: MESSAGES.city.required })
      .default(''),
    state: z
      .enum(stateNames, {
        required_error: MESSAGES.state.required,
        invalid_type_error: MESSAGES.state.required,
      })
      .nullable(),
    email: z
      .string({
        required_error: MESSAGES.email.required,
      })
      .email({ message: MESSAGES.email.valid })
      .default(''),
    phone: z
      .string({
        required_error: MESSAGES.phone.required,
      })
      .refine((value) => matchIsValidTel(value), {
        message: MESSAGES.phone.valid,
      })
      .default(''),
  })
  .required();

export type TBuyerSchema = z.infer<typeof schema>;
