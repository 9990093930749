import { TextField, TextFieldProps } from '@mui/material';
import { memo } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

export interface IControlledTextFieldProps
  extends Omit<ControllerProps, 'render'> {
  TextFieldProps: TextFieldProps;
}

export const ControlledTextField = memo<IControlledTextFieldProps>(
  ({ TextFieldProps, ...controllerProps }) => {
    return (
      <Controller
        {...controllerProps}
        render={({ field }) => <TextField {...TextFieldProps} {...field} />}
      />
    );
  },
);

export default ControlledTextField;
