import { zodResolver } from '@hookform/resolvers/zod';
import { FieldErrors, useForm, UseFormProps } from 'react-hook-form';

import { schema, TBuyerSchema } from './schema';

export type IUseBuyerFormProps = UseFormProps<TBuyerSchema>;

export const useBuyerForm = ({ defaultValues = {} }: IUseBuyerFormProps = {}) =>
  useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

export type TUseBuyerFormReturn = ReturnType<typeof useBuyerForm>;

export type TBuyerFormFieldErrors = FieldErrors<TBuyerSchema>;
