import {
  AlertTitle,
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import * as Sentry from '@sentry/react';
import {
  ApplePay,
  CashAppPay,
  CreditCard,
  PaymentForm as SquarePaymentForm,
  PaymentFormProps as SquarePaymentFormProps,
  VerifyBuyerDetails,
} from '@tmh-dev/react-square-payments';
import { useSnackbar } from 'notistack';
import { Fragment, memo, useCallback } from 'react';
import Countdown from 'react-countdown';
import ReactGA from 'react-ga4';
import { FieldErrors } from 'react-hook-form';

import soundStudiosLogo from '../../../assets/sound_studios_logo.png';
import { IOrderData } from '../../../lib/api/orders';
import { useCreatePaymentMutation } from '../../../lib/api/payments';
import { isEmpty } from '../../../lib/utils';
import BuyerForm from './BuyerForm/BuyerForm';
import { TBuyerSchema } from './BuyerForm/schema';
import { useBuyerForm } from './BuyerForm/useBuyerForm';
import { CONFIRMATION_URL } from './constants';
import { useDiscounts } from './hooks';

const { VITE_SCHEDULING_URL } = import.meta.env;

export interface IPaymentProps {
  order: IOrderData;
}

export const PaymentForm = memo<IPaymentProps>(({ order }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const {
    buyer,
    calendarName,
    expiresAt,
    googlePlaceId,
    id: orderId,
    price,
    serviceName,
  } = order;
  const { email: defaultEmail, phone: defaultPhone } = buyer;
  const { totalAmount, discounts, discountedAmount, depositAmount } = price;

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
  } = useBuyerForm({
    defaultValues: {
      email: defaultEmail,
      phone: defaultPhone,
    },
    reValidateMode: 'onChange',
  });

  const handlePaymentFailure = useCallback(() => {
    ReactGA.gtag('event', 'payment_failure', {
      event_label: 'Received Failed Payment Response',
    });
    enqueueSnackbar({
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      autoHideDuration: 15000,
      message:
        'Payment has been declined, we are unable to process your payment. Please double check your billing information or try a different payment method.',
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  const handlePaymentSuccess = useCallback(
    (paymentId: string) => {
      ReactGA.gtag('event', 'purchase', {
        transaction_id: paymentId,
        currency: 'USD',
        value: depositAmount,
        items: [
          {
            item_name: serviceName,
            affiliation: calendarName,
            location_id: googlePlaceId,
            quantity: 1,
          },
        ],
      });
      window.location.replace(CONFIRMATION_URL);
    },
    [depositAmount, calendarName, googlePlaceId, serviceName],
  );

  const { mutateAsync, isLoading } = useCreatePaymentMutation({
    onError: () => {
      handlePaymentFailure();
    },
  });

  const createPaymentRequest: SquarePaymentFormProps['createPaymentRequest'] =
    useCallback(
      () => ({
        countryCode: 'US',
        currencyCode: 'USD',
        total: {
          amount: depositAmount.toFixed(2),
          label: 'Total',
        },
      }),
      [depositAmount],
    );

  const createVerificationDetails: SquarePaymentFormProps['createVerificationDetails'] =
    async () => {
      let details: VerifyBuyerDetails | undefined = undefined;
      let errors: FieldErrors<TBuyerSchema> = {};

      const handle = handleSubmit(
        ({
          givenName,
          familyName,
          email,
          phone,
          addressLineOne,
          addressLineTwo,
          city,
          state,
        }) => {
          const addressLines: string[] = [];
          if (addressLineOne) {
            addressLines.push(addressLineOne);
            if (addressLineTwo) {
              addressLines.push(addressLineTwo);
            }
          }
          details = {
            intent: 'CHARGE',
            amount: depositAmount.toFixed(2),
            currencyCode: 'USD',
            billingContact: {
              givenName,
              familyName,
              email,
              phone,
              addressLines,
              city,
              state: state ?? undefined,
              countryCode: 'US',
            },
          };
        },
        (e) => (errors = e),
      );

      await handle();

      if (!details || !isEmpty(errors)) {
        throw new Error(JSON.stringify(errors));
      }

      return details;
    };

  const tokenizationResponseHandler: SquarePaymentFormProps['tokenizationResponseReceived'] =
    useCallback(
      async ({ errors, token: sourceId }, verifiedBuyer) => {
        if (!errors?.length && sourceId) {
          const result = await mutateAsync({
            orderId,
            sourceId,
            verificationToken: verifiedBuyer?.token,
          });

          if (['COMPLETED', 'APPROVED', 'PENDING'].includes(result.status)) {
            handlePaymentSuccess(result.id);
          } else {
            handlePaymentFailure();
          }
        } else {
          Sentry.captureException(errors);
        }
      },
      [handlePaymentFailure, handlePaymentSuccess, mutateAsync, orderId],
    );

  const { earlyBirdDiscount, weeknightDiscount } = useDiscounts(discounts);
  const hasDiscounts = discounts.length > 0;

  const isCreditCardButtonDisabled = !isEmpty(formErrors) || isLoading;

  return (
    <Paper elevation={2} sx={{ position: 'relative' }}>
      <Box mb={4} mt={6} width="100%">
        <img
          src={soundStudiosLogo}
          alt="sound studios logo"
          style={{
            height: 20,
          }}
        />
      </Box>

      <Divider />

      <Box p={4}>
        <Countdown
          date={expiresAt}
          renderer={({ completed, formatted: { minutes, seconds } }) => (
            <Grid container spacing={2}>
              <Grid xs={12} marginBottom={2}>
                <Grid container justifyContent="center" xs={12} mb={2}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {serviceName}
                  </Typography>
                </Grid>

                <Grid container direction="column" xs={12} mb={2}>
                  <Grid container justifyContent="space-between" xs={12}>
                    <Grid>Subtotal</Grid>
                    <Grid>${totalAmount.toFixed(2)}</Grid>
                  </Grid>

                  {earlyBirdDiscount > 0 && (
                    <Grid container justifyContent="space-between">
                      <Grid>Early Bird Discount</Grid>
                      <Grid>-${earlyBirdDiscount.toFixed(2)}</Grid>
                    </Grid>
                  )}

                  {weeknightDiscount > 0 && (
                    <Grid container justifyContent="space-between">
                      <Grid>Weeknight Discount</Grid>
                      <Grid>-${weeknightDiscount.toFixed(2)}</Grid>
                    </Grid>
                  )}

                  <Grid container justifyContent="space-between">
                    <Grid sx={{ fontWeight: 'bold' }}>
                      Total Price {hasDiscounts ? 'After Discount(s)' : ''}
                    </Grid>
                    <Grid sx={{ fontWeight: 'bold' }}>
                      ${discountedAmount.toFixed(2)}
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="space-between">
                    <Grid sx={{ fontWeight: 'bold' }}>Deposit (Due Now)</Grid>
                    <Grid sx={{ fontWeight: 'bold' }}>
                      ${depositAmount.toFixed(2)}
                    </Grid>
                  </Grid>
                </Grid>

                {completed ? (
                  <Grid>
                    <Alert
                      variant="filled"
                      severity="warning"
                      sx={{ textAlign: 'left' }}
                    >
                      <AlertTitle>Session selection expired</AlertTitle>
                      The hold on your selected session has expired. Click{' '}
                      <Link href={VITE_SCHEDULING_URL}>here</Link> to return to
                      the booking page to book a session.
                    </Alert>
                  </Grid>
                ) : (
                  <Fragment>
                    <Grid>
                      <Alert
                        variant="filled"
                        severity="info"
                        sx={{ justifyContent: 'center', paddingTop: 2 }}
                      >
                        <AlertTitle>
                          Time remaining to book session:&nbsp;
                          {minutes}:{seconds}
                        </AlertTitle>
                      </Alert>
                    </Grid>

                    <SquarePaymentForm
                      applicationId={import.meta.env.VITE_SQUARE_APPLICATION_ID}
                      locationId={import.meta.env.VITE_SQUARE_LOCATION_ID}
                      tokenizationResponseReceived={tokenizationResponseHandler}
                      createPaymentRequest={createPaymentRequest}
                      createVerificationDetails={createVerificationDetails}
                    >
                      <Grid container>
                        <Grid
                          container
                          direction="column"
                          spacing={0.5}
                          xs={12}
                        >
                          <Grid>
                            <CashAppPay
                              shape="semiround"
                              size="medium"
                              width="full"
                            />
                          </Grid>

                          <Grid>
                            <ApplePay />
                          </Grid>
                        </Grid>

                        <Grid mt={3} xs={12}>
                          <Divider>Or pay with card</Divider>
                        </Grid>

                        <Grid container spacing={1} xs={12}>
                          <BuyerForm control={control} />

                          <Grid xs={12} mt={1}>
                            <CreditCard
                              style={{
                                '.input-container.is-focus': {
                                  borderColor: theme.palette.primary.main,
                                },
                              }}
                              callbacks={{
                                errorClassAdded: () =>
                                  handleSubmit(() => {
                                    // Trigger validation of buyer form fields
                                  })(),
                              }}
                              render={(Button) => (
                                <Button
                                  isLoading={isCreditCardButtonDisabled}
                                  css={{
                                    backgroundColor: theme.palette.primary.main,
                                    '&:hover': {
                                      backgroundColor:
                                        theme.palette.primary.light,
                                    },
                                    '&:active': {
                                      backgroundColor:
                                        theme.palette.primary.light,
                                      border: 'none',
                                      outline: 'none',
                                    },
                                    '&:focus': {
                                      backgroundColor:
                                        theme.palette.primary.light,
                                      border: 'none',
                                      outline: 'none',
                                    },
                                    '&:disabled': {
                                      backgroundColor:
                                        theme.palette.primary.light,
                                    },
                                  }}
                                >
                                  <Typography fontWeight="bold" fontSize={16}>
                                    Pay
                                  </Typography>
                                </Button>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </SquarePaymentForm>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          )}
        />
      </Box>

      <Backdrop
        open={isLoading}
        sx={{
          height: '100vh',
          width: '100vw',
          zIndex: theme.zIndex.drawer + 1,
          position: 'fixed',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size={50}
          sx={{ color: 'white', display: 'block', marginBottom: 2 }}
        />

        <Typography fontWeight="bold" sx={{ color: 'white', display: 'block' }}>
          Payment processing...
        </Typography>
      </Backdrop>
    </Paper>
  );
});

export default PaymentForm;
