import get from 'lodash/get';

import { TBuyerFormFieldErrors } from '../useBuyerForm';

export const getErrorMessage = <
  T extends TBuyerFormFieldErrors,
  K extends keyof T,
>(
  errors: T,
  keyName: K,
): string | undefined => {
  return get(errors, [keyName, 'message']);
};
