import { MutationFunction, useMutation } from 'react-query';

import { baseClient } from './baseClient';

export interface ICreateOrderVariables {
  appointmentId: string;
  locationId: string;
  serviceId: string;
}

export type TDiscountName = 'weekday' | 'midday-weekday' | 'midday-weekend';

export interface IDiscount {
  type: 'FIXED_AMOUNT';
  name: TDiscountName;
  amountInDollars: number;
}

export interface IOrderData {
  id: string;
  amount: string;
  serviceName: string;
  calendarName?: string;
  googlePlaceId?: string;
  buyer: {
    givenName: string;
    familyName: string;
    email: string;
    phone: string;
  };
  price: {
    totalAmount: number;
    discountedAmount: number;
    depositAmount: number;
    discounts: IDiscount[];
  };
  createdAt: string;
  expiresAt: string;
}

export const createOrderMutation: MutationFunction<
  IOrderData,
  Omit<ICreateOrderVariables, 'locationId'>
> = (variables: Omit<ICreateOrderVariables, 'locationId'>) => {
  return baseClient
    .post('order', {
      json: {
        ...variables,
        locationId: import.meta.env.VITE_SQUARE_LOCATION_ID,
      },
      retry: 3,
    })
    .json();
};

export const useCreateOrderMutation = () =>
  useMutation<IOrderData, unknown, Omit<ICreateOrderVariables, 'locationId'>>(
    createOrderMutation,
  );
