import { useMemo } from 'react';

import { IDiscount, TDiscountName } from '../../../../lib/api/orders';

const getDiscountValue = (
  discounts: IDiscount[],
  discountNames: TDiscountName[],
): number =>
  discounts
    .filter(({ name }) => discountNames.includes(name))
    .reduce((acc, { amountInDollars }) => acc + amountInDollars, 0);

export const useDiscounts = (discounts: IDiscount[]) => {
  const earlyBirdDiscount = useMemo(
    () => getDiscountValue(discounts, ['midday-weekday', 'midday-weekend']),
    [discounts],
  );

  const weeknightDiscount = useMemo(
    () => getDiscountValue(discounts, ['weekday']),
    [discounts],
  );

  return {
    earlyBirdDiscount,
    weeknightDiscount,
  };
};
