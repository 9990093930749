import {
  MuiTelInput as PhoneField,
  MuiTelInputProps as PhoneFieldProps,
} from 'mui-tel-input';
import { memo } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

export interface IControlledTextFieldProps
  extends Omit<ControllerProps, 'render'> {
  PhoneFieldProps?: PhoneFieldProps;
}

export const ControlledPhoneField = memo<IControlledTextFieldProps>(
  ({ PhoneFieldProps = {}, ...controllerProps }) => {
    return (
      <Controller
        {...controllerProps}
        render={({ field }) => <PhoneField {...PhoneFieldProps} {...field} />}
      />
    );
  },
);

export default ControlledPhoneField;
